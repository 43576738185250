







































import Vue from "vue";
import { DataTableHeader } from "vuetify";
import { MotivationMessageType } from "@/store/interfaces/MotivationMessageType";
import level from "@/modules/level";
import MotivationMessage from "@/store/models/MotivationMessage";
import Request from "@/modules/request";
import AddMessageDialog from "./AddMessageDialog.vue";

export default Vue.extend({
  components: {
    AddMessageDialog,
  },
  computed: {
    sortedArray() {
      // Copying the array to avoid mutating a prop
      const array = [...this.items];
      array.sort((a, b) => {
        return (
          (a as MotivationMessage).scoreThreshold -
          (b as MotivationMessage).scoreThreshold
        );
      });
      return array;
    },
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    messageType: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    editIndex: 0,
    editDialog: false,
    addDialog: false,
    headers: [
      {
        text: "Message",
        value: "text",
      },
      {
        text: "Niveau de déclenchement",
        value: "scoreThreshold",
      },
      {
        text: "Editer",
        value: "tools",
        width: 50,
      },
    ] as DataTableHeader[],
  }),
  methods: {
    percentage: level.percentageToNextLevel,
    level: level.returnLevel,
    postUpdateCycle() {
      this.$store.dispatch("getMotivationMessages");
    },
    closeEditDialog() {
      this.editIndex = 0;
      this.editDialog = false;
    },
    openEditDialog(item: Record<string, any>) {
      this.editIndex = item.id;
      this.editDialog = true;
    },
    async deleteMessage(id: number) {
      try {
        await Request.shared.delete(`/motivationmessage/${id}`);
        this.$store.dispatch("getMotivationMessages");
      } catch (error) {
        console.error(error);
      }
    },
  },
});
