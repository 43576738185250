const pointsPerLevel = [
    0, 5, 10, 15, 20, 25, 30, 36, 41, 46, 51, 57, 62, 67, 73, 78, 83, 89, 94, 100, 106, 111, 117, 123, 128, 134, 140, 146, 151, 157, 163, 169, 175, 181, 188, 194, 200, 206, 213, 219, 225, 232, 238, 245, 252, 258, 265, 272, 279, 286, 293, 300, 307, 314, 322, 329, 337, 344, 352, 360, 368, 376, 384, 392, 400, 408, 417, 426, 434, 443, 452, 461, 471, 480, 490, 500, 510, 520, 531, 542, 553, 564, 576, 588, 600, 613, 626, 639, 654, 668, 684, 700, 717, 735, 755, 776, 800, 827, 858, 900, 999
]

const returnLevel = (points: number): number => {
    if (points >= 1000) {
        return 100;
    } else if (points >= 0) {
        const level = Math.floor((-0.0001 * points * points) + 0.2 * points)
        return level;
    }
    else {
        return 0;
    }
}

const percentageToNextLevel = (points: number): number => {
    // We first get the current level
    const level = returnLevel(points);
    if (level == 100) {
        // If level is 100, there is no future level, we can return 100%
        return 100
    } else {
        // are at level 99 max, we can query the pointsPerLevel with level+1
        const lowerBound = pointsPerLevel[level]
        const upperBound = pointsPerLevel[level + 1] +1
        // We calculate the points we have in excess of the lowerBound
        const excess = points - lowerBound
        return Math.floor(100 * excess / ((100 * upperBound - 100 * lowerBound) / 100))
    }
}

export default {
    returnLevel,
    percentageToNextLevel,
    pointsPerLevel
}