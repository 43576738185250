
























































import level from "@/modules/level";
import Request from "@/modules/request";
import rules from "@/modules/rules";
import Vue from "vue";

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.text = "";
          this.scoreThreshold = 0;
          (this.$refs.addMessageForm as any).resetValidation();
          this.$emit("close");
        }
      },
    },
  },
  data: () => ({
    text: "",
    scoreThreshold: 0,
    addLoading: false,
    valid: false,
    rules: rules(),
  }),
  methods: {
    percent: level.percentageToNextLevel,
    level: level.returnLevel,
    async addMessage() {
      try {
        this.addLoading = true;
        await Request.shared.post(`motivationMessage`, {
          text: this.text,
          scoreThreshold: this.scoreThreshold,
          type: this.type,
        });
        this.addLoading = false;
        this.showDialog = false;
        this.$emit("add");
      } catch (error) {
        // TBD
      }
    },
  },
});
