






























import Vue from "vue";
import { MotivationMessageType } from "@/store/interfaces/MotivationMessageType";
import MotivationMessage from "@/store/models/MotivationMessage";
import MessageDataTable from "@/components/MessageDataTable.vue";

export default Vue.extend({
  components: {
    MessageDataTable,
  },
  computed: {
    wellBeingMessages(): MotivationMessage[] {
      return (
        this.$store.state.motivationMessages as MotivationMessage[]
      ).filter((value) => {
        return value.type == MotivationMessageType.wellBeing;
      });
    },
    healthMessages(): MotivationMessage[] {
      return (
        this.$store.state.motivationMessages as MotivationMessage[]
      ).filter((value) => {
        return value.type == MotivationMessageType.health;
      });
    },
  },
  data: () => ({
    types: MotivationMessageType,
  }),
  beforeMount() {
    this.$store.dispatch("getMotivationMessages");
  },
});
